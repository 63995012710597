.App {
  text-align: center;
}

body {
  /* font-family: 'Roboto', sans-serif; */
  font-size: 1em
  
}

h1, h2 {
  font-size:1.25em;
  margin:0;
  padding:0;
}

h3, h4, h5, h6 {
  font-size: 1em;
  position: relative;
  margin: 0;
  padding: 0;

}


.bold {
  font-weight: bold;
}

hr {
  height: 1px;
  border: none;
  max-width: 1080px;
}

input {
  height: 3em;
  margin-left:0.5em;
}

.key-input {
  background-color: rgba(255,255,255,0.2); 

  margin-left: 2px;
  margin-right: 2px;
  padding: 2px 8px; /* Padding inside the rectangle */
  border-radius: 4px; /* Soft corners */
  display: inline-block; /* Ensure it fits snugly around the text */
}

.top-wrapper {
  position: relative;
  margin: 20px;

}

.image-generator-wrapper {
  width: 100%;
  height: 100%;


}

.image-generator-container {



  border-color: #333;
  position: relative;
  width: 100%;
  height: 100%; /* or set a specific height */
  overflow: hidden; /* Change from 'auto' to 'hidden' */
}

.interface-wrapper {
  position: relative;
  max-width: 1080px;
  margin: auto;
}


.image-generator-section {
  max-width:1080px;

  border-style: solid;
  border-width: 1px;


  position: relative;
  height: 80vh; /* or set a specific height */
  overflow:hidden; /* Change from 'auto' to 'hidden' */
  margin:auto;
}


.app-container {
  height: 100vh; /* Full viewport height */
  overflow-y: none; /* Enable vertical scrolling */
}

.content-section {
  min-height: 20vh; /* Full viewport height for content */
  padding-left: 20px;
  padding-right: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr; 
  gap: 10px; /* Adds space between columns */
  max-width: 1080px;
  margin: auto;
}



.grid-container.footer {
  text-align: right;
}

.loading-container {
  margin-left: 2vw;
  margin-top: 2vw;
}

.submit-container {

  border: 2px dashed #333;  /* You can adjust thickness and color */
  border-radius: 8px;       /* Adjust the pixel value to make corners more or less curved */
  padding: 20px;  
  display: flex;
  flex-direction: column;
  gap: 10px;

}

button {
  font-size: 16px;
  width: 8em;
  height: 100%;
  padding: 0.25em;
}

.buttons {
  gap: 0.5em;
  display: flex;
  align-items: end;
  justify-content: right;
  flex-direction: row;
}

.button-download {
  width: 60px;
}

.options {
  display: flex;
  flex-direction: row;
  position: relative;
  right: 0;
  justify-content:space-between;
  margin: 0.5em 0;
}

.paint-icon {
  width: 50px;
}

.color-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5em 0;
}