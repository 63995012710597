

.gallery-section {
  position: relative;
  padding-left: 2vw;
  padding-right: 2vw;
  display:block;

}

.gallery-container {
    margin-top: 4em;
}


.gallery-grid {
    display: grid;
    width: 100%;

    grid-template-columns: 1fr 1fr 1fr 1fr; /* Creates 3 equal columns */
    gap: 50px; /* Adds space between columns */
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    align-self: center;
    justify-self: center;
    text-align: left;
    /* Add these to center the inner wrapper */
    display: flex;
    justify-content: center;
    cursor: pointer;

  }
  
  /* Create a wrapper div around your img */
  .gal-image-wrapper {
    display: inline-block; /* This makes it shrink to fit the image */
    line-height: 0; /* Removes any extra space */
    align-self: center;
  }
  
  .gal-image-wrapper img {
    border: 1px solid; /* Simplified border syntax */
    max-height: 250px;
    width: auto; /* Change from 100% to auto */
    max-width: 100%; /* Ensure it doesn't overflow container */
    object-fit: scale-down;
  }
  
  
  .caption {
    padding: 8px 0px;
  }
  
  .caption h3 {
    margin: 0 0 8px;
    font-size: 16px;
  }
  
  .caption p {
    margin: 0 0 4px;
    font-size: 14px;
    opacity: 0.7;
  }
  
  .caption small {
    font-size: 12px;
    opacity: 0.5;
  }

  @media (max-width: 1200px) {
    .gallery-grid {
        grid-template-columns: 1fr 1fr 1fr; /* 3 columns for medium viewports */
    }

    .gallery-section {
      position: relative;
      padding-left: 2vw;
      padding-right: 2vw;
      display:block;
  }
  
}

@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: 1fr 1fr; /* 2 columns for small viewports */
    }
}

@media (max-width: 480px) {
    .gallery-grid {
        grid-template-columns: 1fr; /* 1 column for very small viewports */
    }

    .gallery-section {

      padding-left: 20px;
      padding-right: 20px;

  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal-content {
  position: relative;
  max-width: 64rem; /* equivalent to max-w-4xl */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.modal-image {
  max-height: 80%;
  max-width: 100%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: -350px;
  right: 1rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.close-button:hover {
  color: #d1d5db; /* light gray on hover */
}

.nav-button {
  position: absolute;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 0.5rem;
  transition: color 0.2s ease;
}

.nav-button:hover {
  color: #d1d5db;
}

.nav-button-left {
  left: -5rem;
}

.nav-button-right {
  right: -5rem;
}

/* Add some basic animation for the modal */
.modal-overlay {
  animation: fadeIn 0.2s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}